<template>
  <div class="setPointer">
    <div class="setMain box box_column">
      <ul class="box center ul">
        <li v-for="(item, index) in tabList" :key="index" class="box around pointer" :class="tabItem.id == item.id ? 'activeColor' : ''" @click="changeTab(item)">
          <div class="box center">
            <div class="mR10">{{ item.name }}</div>
            <img src="../../assets/images/img/Combined Shape.png" alt="" />
          </div>
        </li>
      </ul>
      <ul class="ul1 box center">
        <li v-for="(item, index) in twoList" :key="index" :class="active == item.id ? 'activeColor' : ''" @click="changeTwo(item)">
          <div>{{ item.name }}</div>
        </li>
      </ul>
      <div class="flex1 box center flexTop">
        <div class="" style="width: 70%; height: 100%">
          <zheng1></zheng1>
        </div>
        <div style="width: 30%; height: 100%">
          <ce1></ce1>
        </div>
        <div class="btnBox1">
          <!-- 截面，，测点列表 -->
          <div class="win" v-if="winStatus == 1">
            <div class="close pointer" @click="closeWin">X</div>
            <ul class="winul">
              <li v-for="(item, index) in arrList" :key="index" class="box center">
                <div class="mB10 pointer" :class="active1 == item.id ? 'activeColor1' : 'activeNor'" @click="getId(item, index)">{{ item.code }}</div>
              </li>
            </ul>
          </div>
          <el-tooltip content="测点列表" placement="left">
            <div class="btn box center around pointer" style="margin-bottom: 97px" :class="{ tabActiveColor: winStatus == 1 }" @click="addLine">
              <span class="iconfont icon-liebiao_button"></span>
            </div>
          </el-tooltip>
          <el-tooltip content="位置锁定" placement="left">
            <div class="btn box center around mB10 pointer" :class="{ tabActiveColor: lockItem.lock == 1 }" @click="saveData">
              <span class="iconfont icon-suoding_icon"></span>
            </div>
          </el-tooltip>
          <el-tooltip content="位置解锁" placement="left">
            <div class="btn box center around pointer" :class="{ tabActiveColor: lockItem.lock == 2 }" @click="editData">
              <span class="iconfont icon-jiesuo"></span>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zheng1 from "./components/zheng1";
import ce1 from "./components/ce1";

export default {
  name: "setPointer",
  components: {
    zheng1,
    ce1
  },
  data() {
    return {
      projectId: "",
      id: "",
      genre: "1", //1传感器  2视频
      tabList: [],
      tabItem: {},
      twoList: [],
      active: "",
      winStatus: "", //测点弹框
      type1: "", //一级菜单的类型
      type2: "", //二级菜单的类型
      arrList: [], //测点列表
      lockItem: { lock: -1 },
      data: {}, //获取传过来的值
      active1: ""
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("goCeData", this.goCeData); //获取发送过来的x,y
  },

  //安装
  mounted() {
    this.aloneTypeData(); //获取类型分类
  },

  //方法
  methods: {
    goCeData(e) {
      this.data = e;
      console.log("data", this.data);
    },

    //切换菜单显示二级菜单
    changeTab(item) {
      console.log("item", item, item.list);
      this.tabItem = item;
      this.twoList = item.list;
      if (item.id != 4) {
        //4是视频
        this.genre = 1;
      } else {
        this.genre = 2;
        this.videoData();
      }
    },
    changeTwo(item) {
      this.arrList = [];
      this.lockItem = { lock: -1 };
      console.log("item", item);
      this.active = item.id; //着色
      this.type2 = item.id; //类型id
      this.targetData();
      this.$bus.$emit("pointerItem", item);
    },

    // 测点列表弹框
    addLine() {
      this.winStatus = 1;
    },
    closeWin() {
      this.winStatus = 0;
    },

    getId(item, index) {
      console.log("item设置锁", item, "index", index, this.lockItem);
      this.id = item.id;
      this.winStatus = 0;
      this.active1 = item.id;
      this.lockItem = item;

      console.log("item设置锁22222222", item, "index", index, this.lockItem);
      this.$bus.$emit("pointerData", item.id);
      this.$forceUpdate();
    },

    // ---------------------------------请求数据-----------------------------------------
    aloneTypeData() {
      this.$axios
        .get(`${this.baseURL}synthesis/target/type`)
        .then((res) => {
          console.log("单桥类型列表", res);
          this.tabList = res.data.data;
          this.tabList.push({ id: "4", name: "视频监控" });
          this.twoList = this.tabList[0].list;
          this.tabItem = this.tabList[0];
        })
        .catch((err) => {});
    },
    // 根据类型查测点
    targetData() {
      this.$axios
        .get(`${this.baseURL}synthesis/target/list/${this.projectId}?typeIds=${this.type2}`)
        .then((res) => {
          console.log("根据类型查测点", res.data.data[0].list);
          this.arrList = res.data.data[0].list;

          if (this.arrList.length) {
            // lock  -1 无锁  1锁   2 解锁

            this.arrList.forEach((item) => {
              this.$set(item, "lock", 1);
            });

            this.getId(this.arrList[0], 0);
          }
        })
        .catch((err) => {});
    },

    // 查询设置列表
    videoData() {
      this.$axios
        .get(`${this.baseURL}synthesis/video/${this.projectId}`)
        .then((res) => {
          console.log("视频列表", res);
          // this.arrList
          var data = res.data.data;
          data.forEach((item, index) => {
            item.name = item.videoName;
          });
          this.twoList = data;
        })
        .catch((err) => {});
    },

    //保存位置值（下，y）
    saveData() {
      var data = [
        {
          genre: this.genre,
          projectId: this.projectId,
          targetId: this.id,
          viewsId: this.data.ceId,
          x: this.data.x,
          y: this.data.y
        }
      ];
      console.log("data", data);

      this.$axios
        .post(`${this.baseURL}synthesis/save/views/target`, data)
        .then((res) => {
          console.log("保存三视图xy", res);
          this.$message.success("设置成功");
          this.$bus.$emit("freePointer", "0"); //解锁点的控制   0冻结  1解冻  c1
        })
        .catch((err) => {});
    },

    // 解锁图标
    editData() {
      this.arrList.forEach((item) => {
        if (item.id === this.lockItem.id) {
          item.lock = 2;
        }
      });
      this.$bus.$emit("freePointer", "1"); //解锁点的控制   0冻结  1解冻 c1
    }
  }
};
</script>
<style scoped lang="scss">
.setPointer {
  width: 100%;
  height: 100%;
}

.setMain {
  width: 100%;
  height: 100%;
}

.main {
  height: 100%;
}
.bottom {
  height: 100%;
}

.title {
  padding-right: 30px;
}
.shu {
  width: 1px;
  height: 16px;
  background: #3a4a7b;
}
.ul {
  margin-top: 16px;
  margin-bottom: 16px;
  li {
    width: 118px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    border-radius: 16px;
    margin-right: 24px;
    border: 1px solid #2667db;
  }
}

.zhengBox {
  width: 60%;
  height: 100%;
  margin-right: 8px;
}
.ceBox {
  height: 100%;
}
.ul1 {
  margin-bottom: 25px;
  li {
    cursor: pointer;
    padding: 0 10px;
    // width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    background: #233361;
    border-radius: 15px;
    margin-right: 16px;
    color: #7387c6;
  }
}
.activeColor {
  background: #0050b4 !important;
  color: #fff !important;
}

.btn {
  width: 34px;
  height: 34px;
  background: rgba($color: #ccdaff, $alpha: 0.2);
  border-radius: 28px;
  text-align: center;
  height: 34px;
  margin-right: 16px;
}

// 切面图弹框
.btnBox1 {
  position: relative;
  .win {
    padding: 20px;
    position: absolute;
    left: -330px;
    width: 320px;
    height: 300px;
    // border: 1px solid #ccc;
    background: url("../../assets/images/img/set/tankuang_jianbian_bg.png");
    background-size: 100% 100%;
    overflow-y: auto;
    border: 1px solid #3d6bd0;
    border-radius: 8px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .activeColor1 {
    color: #fff;
  }
  .activeNor {
    color: #7387c6;
  }
}
.tabActiveColor {
  background: #0050b4 !important;
}
</style>

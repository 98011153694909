<template>
  <!-- positionSet -->
  <div class="positionIndex winStyle">
    <div class="main winStyle box box_column">
      <div class="top">
        <ul class="ul box center ulTab">
          <li v-for="(item, index) in list" :key="index" class="box center pointer" @click="tabClick(item.id)">
            <div style="width: 100%" class="fu">
              <div>{{ item.name }}</div>
              <div class="tiao" v-if="active == item.id"></div>
            </div>
            <div class="shu" v-if="index != list.length - 1"></div>
          </li>
        </ul>
      </div>
      <div class="flex1 bottom">
        <div v-if="active == 1" style="width: 100%; height: 100%">
          <importPicture></importPicture>
        </div>
        <div v-if="active == 2" style="width: 100%; height: 100%">
          <setPointer></setPointer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import importPicture from "./importPicture";
import setPointer from "./setPointer";
export default {
  name: "positionIndex",
  components: {
    importPicture,
    setPointer
  },
  data() {
    return {
      active: "1",
      list: [
        { id: "1", name: "图纸导入" },
        { id: "2", name: "测点布置配置" }
      ]
    };
  },
  //创建
  created() {
    let name = "";

    this.active = this.$route.query.active || "1";
    if (this.active == "1") {
      name = "图纸导入";
    } else {
      name = "测点布置配置";
    }
    let arr = this.$store.state.breadcrumbList;
    if (arr.length > 2) {
      arr.splice(2);
      arr[1].path = `/positionSet?active=${this.active}`;
      arr.push({ name });
      this.$store.commit("getBreadcrumb", arr);
    } else {
      arr[1].path = `/positionSet?active=${this.active}`;
      arr.push({ name });
      this.$store.commit("getBreadcrumb", arr);
    }
    console.log("图纸导入", arr);
  },

  //安装
  mounted() {
    // this.imgData();  //获取三视图信息
  },

  //方法
  methods: {
    //   导入图片  与   设置测点切换
    tabClick(val) {
      console.log("val", val);
      this.active = val;
      this.$router.push({ path: `/positionSet?active=${val}&t=${Date.now()}` });

      // if(val == 2){
      //     console.log('测点');
      //     this.arrList = [{'id':'1','name':'1111'},{'id':'2','name':'22222'}]
      // }
    }

    // ----------------------------请求数据 ----------------------------------
  }
};
</script>
<style scoped lang="scss">
.positionIndex {
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.ul {
  li {
    position: relative;
  }
  li div:first-child {
  }
  li:last-child {
    margin-right: 0;
  }
  .shu {
    width: 1px;
    height: 16px;
    background: #ccc;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.activeColor {
  position: absolute;
  left: 0px;
  bottom: 12px;
  height: 4px;
  width: 100%;
  // border: 1px solid #ccc;
  background: url("../../assets/images/img/tab_chang.png");
  background-size: 100% 100%;
}
.liBox {
  overflow: hidden;
}
.bottom {
  height: 100%;
}

.ulTab {
  .fu {
    position: relative;
  }
  li {
    position: relative;
    cursor: pointer;
  }
  .tiao {
    position: absolute;
    top: 30px;
    width: 100%;
    height: 8px;
    background: url("../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }
  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}
</style>

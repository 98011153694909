<template>
  <!-- 侧视图 -->
  <div class="zheng box">
    <div v-if="list.length == 0" class="box center around" style="width: 100%">
      <div class="box box_column center">
        <div class="iconfont icon-ic_image_upload" style="font-size: 60px"></div>
        <div>上传图片</div>
      </div>
    </div>

    <!-- <div class="img">放大</div> -->
    <div v-if="list.length > 0" class="divBox1Fu box center" style="width: 100%; height: 100%">
      <!-- <div class="title1">{{name}}</div> -->
      <div class="title">剖面图</div>
      <div class="input box center justify">
        <div class="pointer" @click="getLeft">
          <span class="iconfont icon-arrow_left"></span>
        </div>
        <!-- elli width:50px-->
        <div style="" class="textC" :title="name">{{ name }}</div>
        <div class="pointer" @click="getRight">
          <span class="iconfont icon-arrow_right"></span>
        </div>
      </div>
      <div id="divBox1" class="box" @mousewheel.prevent="scaleFun" @mousedown.prevent="draggableFun($event)" :style="scaleImg">
        <!-- <div  id="kuai1" class="box around center pointer" v-if="freeStatus == 1" @mousedown="move">
        </div> -->
        <div id="kuai1" class="box around center pointer iconfont" v-if="freeStatus == 1" @mousedown.stop="move" :class="icon"></div>
        <div id="kuai1" class="box around center noPointer" v-if="freeStatus != 1">
          <span class="iconfont" :class="icon"></span>
        </div>

        <div class="bgImg" v-html="src"></div>
        <!-- <img :src="src" alt="" class="bgImg"> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "zheng",
  data() {
    return {
      projectId: "",
      name: "",
      list: [], //侧视图
      src: "",

      pointerId: "",
      icon: "",

      arrindex: 0,
      arr: [],
      ceId: "", //截面id

      freeStatus: "", //是否解冻控制点

      inputPercentValue: 100, // 默认显示比例

      divTop: 0,
      divLeft: 0
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("pointerData", this.pointerData);
    this.$bus.$on("pointerItem", this.pointerItem); //类型的信息 包含图标
  },

  //安装
  mounted() {
    this.getCeData(); //获取三视图id
    setTimeout(() => {
      this.name = this.arr[0].name; //截面的名字
      this.ceId = this.arr[0].id; //截面id
      this.ceData(this.ceId); //截面的位置
      this.centerShow();
      if (!this.arr[0].axleValueX) {
        this.divLeft = 0;
      } else {
        this.divLeft = this.arr[0].axleValueX;
      }

      if (!this.arr[0].axleValueY) {
        this.divTop = 0;
      } else {
        this.divTop = this.arr[0].axleValueY;
      }
      // 显示缩放比例
      if (this.arr[0].proportion) {
        this.inputPercentValue = this.arr[0].proportion;
      } else {
        this.inputPercentValue = 100;
      }
      let ele = document.getElementById("kuai1");
      let num = (100 / this.inputPercentValue).toFixed(3) * 100 + "%";
      ele.style.transform = `scale(${num})`;
    }, 200);

    this.$bus.$on("freePointer", this.freePointer); //接收 setPointer传过来的解冻信息
  },
  computed: {
    scaleImg() {
      return {
        transform: `scale(${this.inputPercentValue + "%"})`,
        top: this.divTop + "%",
        left: this.divLeft + "%"
      };
      // return `transform:scale(${this.inputPercentValue + '%'})`
    }
  },
  //方法
  methods: {
    // 滚轮 放大/缩小
    scaleFun(e) {
      let direction = e.deltaY > 0 ? "down" : "up";
      if (direction === "up") {
        // 滑轮向上滚动
        this.inputPercentValue += 10;
      } else {
        // 滑轮向下滚动
        if (this.inputPercentValue > 10) {
          this.inputPercentValue -= 10;
        }
      }
      let ele = document.getElementById("kuai1");
      let num = (100 / this.inputPercentValue).toFixed(3) * 100 + "%";
      ele.style.transform = `scale(${num})`;
    },
    // 左右
    getLeft() {
      if (this.arrindex == 0) {
        return;
      }
      this.arrindex = this.arrindex - 1;
      var item = this.arr[this.arrindex];
      console.log("item", item);
      this.name = item.name;
      this.imgSrc(item.id);
      this.ceId = item.id;
      this.ceData(this.ceId); //发送到立面图 x

      let div = document.getElementById("divBox1");
      div.style.left = item.axleValueX || 0 + "%";
      div.style.top = item.axleValueY || 0 + "%";

      // 显示缩放比例
      if (item.proportion) {
        this.inputPercentValue = item.proportion;
      } else {
        this.inputPercentValue = 100;
      }
      let ele = document.getElementById("kuai1");
      let num = (100 / this.inputPercentValue).toFixed(3) * 100 + "%";
      ele.style.transform = `scale(${num})`;
    },
    // 左右
    getRight() {
      if (this.arrindex == this.arr.length - 1) {
        return;
      }
      this.arrindex = this.arrindex + 1;
      var item = this.arr[this.arrindex];
      console.log("item", item);
      this.name = item.name;
      this.imgSrc(item.id);
      this.ceId = item.id;
      this.ceData(this.ceId); //发送到立面图 x

      let div = document.getElementById("divBox1");
      div.style.left = item.axleValueX || 0 + "%";
      div.style.top = item.axleValueY || 0 + "%";

      // 显示缩放比例
      if (item.proportion) {
        this.inputPercentValue = item.proportion;
      } else {
        this.inputPercentValue = 100;
      }
      let ele = document.getElementById("kuai1");
      let num = (100 / this.inputPercentValue).toFixed(3) * 100 + "%";
      ele.style.transform = `scale(${num})`;
    },

    // 获取截面的 参数
    ceData(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/select/views/section/${id}`)
        .then((res) => {
          console.log("获取截面的x", res);
          var data = res.data.data.x;
          this.$bus.$emit("setX", data);
        })
        .catch((err) => {});
    },

    // 接收传过来的测点值
    pointerData(e) {
      console.log("e传过来的测点", e);
      this.pointerId = e;
      this.pointerData1(e);
      // 读取原有的位置
    },

    pointerData1(e) {
      var data = {
        typeIds: "", //字符串类型组
        targetId: e, //指定测点id
        viewsId: this.ceId, //截面id
        genre: "" // 1传感器   2摄像头
      };
      console.log("data", data);

      var path = `typeIds=${data.typeIds}&targetId=${data.targetId}&viewsId=${data.viewsId}&genre=${data.genre}`;
      this.$axios
        .get(`${this.baseURL}synthesis/views/target/${this.projectId}?${path}`)
        .then((res) => {
          console.log("获取截面上的测点xy", res.data.data);
          var arr = res.data.data;

          var dom = document.getElementById("kuai1");
          dom.style.left = arr[0].x + "%";
          dom.style.top = arr[0].y + "%";
        })
        .catch((err) => {});
    },

    // 设置的点是否可使用
    freePointer(e) {
      this.freeStatus = e; //0冻结  1解冻
    },

    pointerItem(e) {
      console.log("e", e);
      this.icon = e.icon;
    },

    // 一进入居中屏幕
    centerShow() {
      var divX = document.getElementById("divBox1").offsetWidth / 2;
      var el = document.getElementById("kuai1");
      //  el.style.left = '45%';   //x轴移动
      //  el.style.top = '45%';     //y轴移动

      // el.style.left = (divX-20) +'px';   //x轴移动
      // var x = divX-20;    //页面显示的值

      // var divY = document.getElementById('divBox1').offsetHeight/2;
      // el.style.top = (divY-20) +'px';     //y轴移动
      // var y = divY-20;    //页面显示的值

      // // this.positionX = x;
      // // this.positionY = y;

      // var x1 = x/document.getElementById('divBox1').offsetWidth;
      // console.log('x轴百分比',  x1.toFixed(3));

      // var y1 = y/document.getElementById('divBox1').offsetHeight;
      // console.log('y轴百分比',  y1.toFixed(3));
    },

    // 计算百分比
    percentage(x, y) {
      var x1 = x / document.getElementById("divBox1").offsetWidth;
      console.log("x轴百分比", x1.toFixed(3));

      var y1 = y / document.getElementById("divBox1").offsetHeight;
      console.log("y轴百分比", y1.toFixed(3));
      x1 = x1 * 100;
      y1 = y1 * 100;
      this.save(x1, y1);
    },

    move(e) {
      let odiv = e.target; // 获取目标元素
      console.log("目标元素eeeeeeeeeeeeeee", odiv);
      //计算出鼠标相对点击元素的位置,e.clientX获取的是鼠标的位置，OffsetLeft是元素相对于外层元素的位置
      let x = e.clientX - odiv.offsetLeft;
      let y = e.clientY - odiv.offsetTop;
      console.log(odiv.offsetLeft, odiv.offsetTop);
      document.onmousemove = (e) => {
        // 获取拖拽元素的位置
        let left = e.clientX - x;
        let top = e.clientY - y;
        //console.log(document.documentElement.clientHeight,odiv.offsetHeight)
        // 把拖拽元素 放到 当前的位置
        if (left <= 0) {
          left = 0;
        } else if (left >= document.documentElement.clientWidth - odiv.offsetWidth) {
          //document.documentElement.clientWidth 屏幕的可视宽度
          left = document.documentElement.clientWidth - odiv.offsetWidth;
        }

        if (top <= 0) {
          top = 0;
        } else if (top >= document.documentElement.clientHeight - odiv.offsetHeight) {
          // document.documentElement.clientHeight 屏幕的可视高度
          top = document.documentElement.clientHeight - odiv.offsetHeight;
        }

        // let maxX = parseInt(document.getElementById('zujian').offsetWidth - parseInt(window.getComputedStyle(e).width));
        // let maxY = parseInt(document.getElementById('zujian').offsetHeight - parseInt(window.getComputedStyle(e).height));
        console.log("zujian", document.getElementById("divBox1"));

        let maxX = document.getElementById("divBox1").offsetWidth; //-40
        let maxY = document.getElementById("divBox1").offsetHeight; //-40

        if (left > maxX) {
          left = maxX;
        }

        if (top > maxY) {
          top = maxY;
        }

        this.positionX = left;
        this.positionY = top;

        this.percentage(left, top); //获取百分比数值
        odiv.style.left = left + "px";
        odiv.style.top = top + "px";
      };
      // 为了防止 火狐浏览器 拖拽阴影问题
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    save(x1, y1) {
      this.$bus.$emit("goCeData", { x: x1, y: y1, ceId: this.ceId });
    },

    // ---------------------请求数据-------------------------------------------------------
    getCeData() {
      this.$axios
        .get(`${this.baseURL}synthesis/views/list/${this.projectId}`)
        .then((res) => {
          console.log("侧视图1", res);
          var list = res.data.data;
          list.forEach((item, index) => {
            if (item.typeId == 3) {
              this.list = item.list;
            }
          });

          if (this.list == null) {
            this.list = [];
          } else {
            this.imgSrc(this.list[0].id);
            this.arr = this.list;
          }
          // console.log('list', this.list);
        })
        .catch((err) => {});
    },

    // 剖面图鼠标拖拽
    draggableFun(e) {
      let div = document.getElementById("divBox1");
      let dragFlag = false;
      let x, y;

      div.onmousedown = function (e) {
        dragFlag = true;
        e = e || window.event;
        // 获取鼠标在元素上的位置（鼠标按下时在元素上得位置）
        x = e.clientX - div.offsetLeft;
        y = e.clientY - div.offsetTop;
      };

      div.onmousemove = function (e) {
        if (dragFlag) {
          e = e || window.event;
          div.style.left = e.clientX - x + "px";
          div.style.top = e.clientY - y + "px";
        }
      };
      // 鼠标抬起事件
      div.onmouseup = function (e) {
        dragFlag = false;
      };
    },

    imgSrc(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          // console.log('获取图片流', res.data);
          this.src = res.data;
        })
        .catch((err) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.zheng {
  width: 100%;
  height: 100%;
  // background: url('../../../assets/images/img/poumian_bg.png');
  // border-radius: 28px;
  // background: linear-gradient(to top, #102048, #17356f);
  background-size: 100% 100%;
  border: 1px solid #0C3380;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  overflow: hidden;
  .img {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .bgImg {
    width: 100%;
    // height: 100%;
    // border: 1px solid #ccc;
  }
  // .title{
  //   position: absolute;
  //   text-align: center;
  //   bottom: 118px;
  //   width: 100%;
  // }
  .title1 {
    position: absolute;
    width: 100%;
    bottom: 148px;
    text-align: center;
  }
  .input {
    position: absolute;
    bottom: 32px;
    width: 140px;
    text-align: center;
    left: 50%;
    margin-left: -70px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #8a94a6;
    background: rgba(0, 7, 23, 0.6);
    padding: 0 20px;
    z-index: 2;
  }
}
.divBox1Fu {
  position: relative;
  overflow: hidden;
}

#divBox1 {
  width: 100%;
  /* // height: 100%; */
  position: absolute;
  /* // border: 1px solid #ccc;
      // left: 0;
      // top: 0; */
}

#kuai1 {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  // background: chartreuse;
  background: url("../../../assets/images/img/set/hover-cedian_light.png");
  background-size: 100% 100%;
}

.title {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 80px;
  text-align: center;
  line-height: 32px;
  height: 32px;
  background: rgba(0, 7, 23, 0.6);
  border-radius: 8px;
  // bottom: 30px;
}
</style>

<template>
  <div class="importPicture box box_column">
    <!--  上传图片 -->
    <div class="upData winStyle newUi" v-if="upImg == 1">
      <div class="close pointer" @click="closeUp(1)">x</div>
      <div class="top">导入图片</div>
      <div class="bottom">
        <div class="box" style="height: 32px">
          <div class="titileName">项目名称</div>
          <el-input v-model="inputName" disabled></el-input>
        </div>
        <div class="box center mT10" style="height: 32px">
          <div class="titileName">类型选择</div>
          <el-select v-model="value" placeholder="请选择" @change="selectId">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="box around mB10 mT10">
          <div class="box center">
            <!-- <span class="iconfont icon-daoru_button mR10"></span>
                          <div>导入</div> -->

            <el-upload ref="upload" :action="upBridgeImgUrl" :headers="uploadHeaders" multiple :limit="1" :file-list="fileListAdd" :on-remove="handleAddRemove" :on-change="handleAddChange" :on-exceed="handleExceed">
              <el-button size="small" type="primary">
                <div class="box around">
                  <div class="box center">
                    <span class="iconfont icon-daoru_button mR10"></span>
                    <div>导入</div>
                  </div>
                </div>
              </el-button>
              <!-- <span slot="tip" class="el-upload__tip">上传文件不超过50M</span> -->
            </el-upload>
          </div>
        </div>
        <div>**注释**立面图，平面图只可导入一张</div>
      </div>
    </div>

    <!-- 上传侧面图 -->
    <div class="upData winStyle newUi" v-if="upImgCe == 1">
      <div class="close pointer" @click="closeUp(2)">x</div>
      <div class="top">导入剖面图</div>
      <div class="bottom">
        <div class="box" style="height: 32px">
          <div class="titileName">项目名称</div>
          <el-input v-model="inputName"></el-input>
        </div>
        <div class="box center mT10" style="height: 32px">
          <!-- <div class="titileName noFlex">类型选择</div> -->
          <div class="noFlex" style="width: 70px">剖面图</div>
          <div class="mL10">
            <div class="box center" style="height: 32px">
              <el-input v-model="inputName1" type="number"></el-input>
              <div>-</div>
              <el-input v-model="inputName1" type="number" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="box around mB10 mT10">
          <div class="box center">
            <!-- multiple -->
            <el-upload ref="upload1" :action="upBridgeImgUrl" :headers="uploadHeaders" :limit="1" :file-list="fileListAdd1" :on-remove="handleAddRemove1" :on-change="handleAddChange1" :on-exceed="handleExceed1">
              <el-button size="small" type="primary">
                <div class="box around">
                  <div class="box center">
                    <span class="iconfont icon-daoru_button mR10"></span>
                    <div>导入</div>
                  </div>
                </div>
              </el-button>
            </el-upload>
          </div>
        </div>
        <!-- <div>**注释**剖面图</div> -->
      </div>
    </div>

    <!-- 删除 -->
    <div class="upData winStyle newUi" v-if="delWin == 1">
      <div class="close pointer" @click="closeUp(3)">x</div>
      <div class="top">删除</div>
      <div class="bottom">
        <div class="box" style="height: 32px">
          <div class="titileName">项目名称</div>
          <el-input v-model="inputName" disabled></el-input>
        </div>
        <div class="box center mT10" style="height: 32px">
          <div class="titileName">类型选择</div>
          <el-select v-model="valueType" value-key="typeId" placeholder="请选择" @change="delType">
            <el-option v-for="item in options1" :key="item.typeId" :label="item.name" :value="item"> </el-option>
          </el-select>
        </div>
        <div class="box center mT10" style="height: 32px">
          <div class="titileName">图片</div>
          <el-select v-model="value1" placeholder="请选择" @change="delType1">
            <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <div class="box around mT20" @click="getDel">
          <div class="btnSave" style="width: 70px">确定</div>
        </div>
      </div>
    </div>

    <div class="btnBox box center">
      <!-- 导入图片按钮  :class="tabActive == item.id ? 'tabActiveColor' : ''"   :class="!myOption ? 'disable_icon' : ''"-->

      <div class="btn box center around pointer" v-for="(item, index) in tabList" :key="index" @click="getTab(item.id)">
        <el-tooltip :content="item.title" placement="top">
          <span class="iconfont" :class="{'icon-shanchu': item.name=='icon-shanchu' ,'icon-poumian_button': item.name=='icon-poumian_button' , 'icon-daoru_button': item.name=='icon-daoru_button' , 'disable_icon': !myOption }" ></span>
        </el-tooltip>
      </div>
    </div>
    <div class="flex1 box center flexTop">
      <div class="box box_column" style="width: 70%; height: 100%">
        <div class="box around" style="height: 60%">
          <zheng></zheng>
        </div>
        <div style="height: 40%" class="box box">
          <fu></fu>
        </div>
      </div>
      <div class="flex1" style="height: 100%">
        <ce></ce>
      </div>
      <!-- 列表界面 -->
      <div style="margin-left: 17px" class="btnBox1">
        <!-- 截面，，测点列表 -->
        <div class="win" v-if="winStatus == 1">
          <!-- <div class="close pointer" @click="closeWin">X</div> -->
          <ul class="winul">
            <li v-for="(item, index) in arrList" :key="index" class="box center">
              <!-- <div class="mR10">
                                <span v-if="go !=item.id" class="iconfont icon-suoding_icon" @click="getClick(item.id)"></span>
                                <span v-if="go ==item.id" class="iconfont icon-jiesuo" @click="getClick(item.id)"></span>
                            </div> -->
              <div class="pointer" :class="active == item.id ? 'activeColor' : 'activeNor'" @click="getId(item, index)">{{ item.name }}</div>
            </li>
          </ul>
        </div>
        <!-- 操作按钮 -->
        <el-tooltip content="剖面图列表" placement="left">
          <div class="btn box center around pointer" :class="{ tabActiveColor: winStatus == 1 }" style="margin-bottom: 97px" @click="addLine">
            <span class="iconfont icon-liebiao_button"></span>
          </div>
        </el-tooltip>
        <el-tooltip content="位置锁定" placement="left">
          <div class="btn box center around mB10 pointer" :class="{ tabActiveColor: lockItem.lock == 1 }" @click="save">
            <span class="iconfont icon-suoding_icon"></span>
          </div>
        </el-tooltip>
        <el-tooltip content="位置解锁" placement="left">
          <div class="btn box center around pointer" :class="{ tabActiveColor: lockItem.lock == 2 }" @click="edit">
            <span class="iconfont icon-jiesuo"></span>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import zheng from "./components/zheng";
import ce from "./components/ce.vue";
import fu from "./components/fu.vue";
import qs from "qs";

export default {
  name: "importPicture",
  components: {
    zheng,
    ce,
    fu
  },
  data() {
    return {
      projectId: "",
      projectName: "项目名称",
      inputName: sessionStorage.getItem("projectName"),

      tabList: [
        { id: "1", name: "icon-daoru_button", title: "导入立面图/平面图" },
        { id: "2", name: "icon-poumian_button", title: "导入剖面图" },
        { id: "3", name: "icon-shanchu", title: "删除图纸" }
      ],

      go: "",
      arrList: [],
      winStatus: "0",

      // 上传立面图 立视图
      upBridgeImgUrl: "",
      upImg: "0", // 显示上传窗口
      upId: "", //上传选择
      fileListAdd: [],
      fileListAdd1: [],
      inputName1: "", //1-1,   1-1
      options: [
        { id: "1", name: "立面图" },
        { id: "2", name: "平面图" }
      ],

      //
      valueType: "", //选择的视图item
      value1: "",
      options1: [],
      options2: [],

      // 上传侧视图窗口
      upImgCe: "0",

      //删除窗口
      delWin: "0",

      lineData: "", //接收传过来的line值
      id: "", //选择的截面 id

      value: "", //窗口下拉选择
      active: "",
      lockItem: { lock: -1 },
      tabActive: "1"
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("getLineData", this.getLineData);
    this.$bus.$emit("changeModel", "");
    this.$bus.$on("getPointIndexActive", this.getPointIndexActive);
  },

  //安装
  mounted() {
    this.upBridgeImgUrl = this.baseURL + "sys/sysImages/save/views";
    this.uploadHeaders = { token: window.sessionStorage.getItem("token") };
    this.imgData(); //获取三视图信息
  },

  //方法
  methods: {
    delType(item) {
      this.valueType = item;
      this.options2 = item.list;
      console.log("this.options2", this.options2);
    },
    delType1() {},

    // 上传change事件
    handleAddChange(file, fileList) {
      console.log("file, fileList", file, fileList);
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        //  this.ruleForm.file_arr = this.fileListAdd
        // this.$refs.ruleForm.validate(valid => {})
      }
      // 上传文件>=限制个数时隐藏上传组件
      // if (fileList.length > 1) {
      //   this.hideUploadAdd = true;
      // }
      console.log("fileListAdd", this.fileListAdd);
      if (this.fileListAdd[0].response) {
        this.upImgSave();
      }

      // console.log('fileListAdd', this.fileListAdd[0].response.data);
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      // this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },

    // 上传侧面
    // 上传change事件
    handleAddChange1(file, fileList) {
      console.log("file, fileList", file, fileList);
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd1 = fileList;
        //  this.ruleForm.file_arr = this.fileListAdd
        // this.$refs.ruleForm.validate(valid => {})
      }
      // 上传文件>=限制个数时隐藏上传组件
      // if (fileList.length > 1) {
      //   this.hideUploadAdd = true;
      // }
      if (this.fileListAdd1[0].response) {
        this.upImgSave1();
      }
      console.log("fileListAdd1", this.fileListAdd1);
      // setTimeout(()=>{
      // }, 300)

      // console.log('fileListAdd', this.fileListAdd[0].response.data);
    },
    // 多选大于限制个数时做提示
    handleExceed1(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove1(file, fileList) {
      // this.hideUploadAdd = false;
      this.handleAddChange1(file, fileList);
    },

    closeUp(val) {
      if (val == 1) {
        this.upImg = 0;
      }
      if (val == 2) {
        this.upImgCe = 0;
      }
      if (val == 3) {
        this.delWin = 0;
      }
    },

    // 导入，删除 图纸
    getTab(id) {
      console.log("id", id);
      this.tabActive = id;
      // 弹出图纸的窗口
      if (!this.myOption) {
          this.$message.error("您没有权限！");
          return;
        }
      if (id == 1) {
       

        this.value = "";
        this.upImg = 1;
        this.upImgCe = 0;
        this.delWin = 0;
      }
      // 导入侧视图图纸
      if (id == 2) {
        // if (!this.myUpload) {
        //   this.$message.error("您没有权限！");
        //   return;
        // }
        this.value = "";
        this.upImgCe = 1;
        this.upImg = 0;
        this.delWin = 0;
      }
      // 删除
      if (id == 3) {
        // if (!this.myDel) {
        //   this.$message.error("您没有权限！");
        //   return;
        // }
        this.value = "";
        this.delWin = 1;
        this.upImgCe = 0;
        this.upImg = 0;
        this.imgData(); //重新获取图片
        // this.options = [
        //   {'id':'1','name':'立面图'},{'id':'2','name':'立视图'},{'id':'3','name':'截面图'}
        // ]
      }
      // this.$bus.$emit('getLine', id);
    },
    closeWin() {
      this.winStatus = 0;
    },

    // 显示加线窗口
    addLine() {
      console.log("点击");

      if (this.winStatus == 1) {
        this.winStatus = 0;
      } else {
        this.winStatus = 1;
      }
      //  this.$bus.$emit('getAddLine','1');
    },

    // 点击获取截面  传递id给立面图
    getId(item, index) {
      console.log("当前点击的测点", item);
      this.id = item.id;
      this.$bus.$emit("getLine", item.id);
      this.$bus.$emit("getLineIndex", index);
      this.active = item.id;
      this.lockItem = item;
      this.winStatus = 0; //选择测点后关闭窗口
    },

    selectId(val) {
      console.log("val", val);
      this.upId = val;
    },

    // // 开锁解锁 -- 点击锁保存
    // getClick(val){
    //     this.go = val;
    //     var arr = {
    //         sectionId: val,  //切面id
    //         viewsId: 3,    //三视图id
    //     }
    //     this.$bus.$emit('getSave', arr);
    // },

    // --------------------提交请求----------------------------------

    // 删除
    getDel() {
      this.$axios
        .delete(`${this.baseURL}synthesis/del/views?viewsId=${this.value1}`)
        .then((res) => {
          console.log("删除上传的图片", res);
          this.imgData(); //删除id后重新读取
          this.$bus.$emit("delData", 1);
        })
        .catch((err) => {});
    },

    // 提交图片上传
    upImgSave() {
      var arr = {
        projectId: this.projectId,
        type: this.value,
        imagesId: this.fileListAdd[0].response.data
      };
      console.log("arr", arr);

      this.$axios
        .post(`${this.baseURL}synthesis/save/views`, qs.stringify(arr))
        .then((res) => {
          console.log("提交图片上传", res);
          this.fileListAdd = [];
          this.fileListAdd1 = [];
          this.$bus.$emit("delData", 1);
        })
        .catch((err) => {});
    },

    // 提交刨面图
    //     var places = $("#places").val()；
    // if(!(/(^[1-9]\d*$)/.test(places))){
    //     alert("名额应为正整数");
    //     return
    // }

    upImgSave1() {
      var places = this.inputName1;
      if (!/(^[1-9]\d*$)/.test(places)) {
        this.$message.error("需为正整数");
        return;
      }
      var arr = {
        projectId: this.projectId,
        type: 3,
        imagesId: this.fileListAdd1[0].response.data,
        name: this.inputName1 + "-" + this.inputName1
      };
      console.log("arr", arr);

      this.$axios
        .post(`${this.baseURL}synthesis/save/views`, qs.stringify(arr))
        .then((res) => {
          console.log("提交侧面图", res);
          this.fileListAdd = [];
          this.fileListAdd1 = [];
          //  this.$refs.upload.clearFiles()
          //  this.$refs.upload1.clearFiles()
          this.$bus.$emit("delData", 1);
        })
        .catch((err) => {});
    },

    // 保存立面图传过来的线
    save() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      if (this.id == "") {
        this.$message.error("请选择测点");
        return;
      }

      console.log("lineData", this.lineData);
      var data = {
        sectionId: this.id,
        viewsId: 1,
        x: this.lineData
      };
      console.log("data", data);

      this.$axios
        .post(`${this.baseURL}synthesis/save/views/section`, data)
        .then((res) => {
          console.log("保存立面图的位置", res);
          this.$bus.$emit("freeStatus", "0"); //保存后线条不能操作

          if (this.arrList.length) {
            this.arrList.forEach((item) => {
              if (item.id === this.lockItem.id) {
                item.lock = 1;
              }
            });
          }
        })
        .catch((err) => {});
    },

    edit() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.arrList.forEach((item) => {
        if (item.id === this.lockItem.id) {
          item.lock = 2;
        }
      });

      this.$bus.$emit("freeStatus", "1"); //线条解冻 可以操作线 0 冻结 1可以操作
    },

    // 接收传过来立面图的值 用于保存
    getLineData(e) {
      this.lineData = e;
    },

    // 获取当前展示的剖面图
    getPointIndexActive(e) {
      this.active = e.id;
      // console.log('这是接收到的剖面图比例数据', e)
    },
    // -------------------数据请求 ----------------------
    //  获取截面的列表
    imgData() {
      var id = this.projectId;
      this.$axios
        .get(`${this.baseURL}synthesis/views/list/${id}`)
        .then((res) => {
          console.log("获取三视图", res.data.data);
          var data = res.data.data;
          this.options1 = data; //删除的下拉菜单
          this.options1.forEach((item, index) => {
            if (item.typeId == this.valueType.typeId) {
              this.options2 = item.list;
            }
          });
          this.value1 = "";

          // arrlist 截面测点列表
          data.forEach((item, index) => {
            if (item.typeId == 3) {
              this.arrList = item.list;
              console.log("这是测点列表", item.list);
              this.lockItem = { lock: -1 };
              if (this.arrList.length) {
                // lock  -1 无锁  1锁   2 解锁

                this.arrList.forEach((item) => {
                  this.$set(item, "lock", 1);
                });

                this.getId(this.arrList[0], 0);
              }
            }
          });
          // this.arrList.push({'id':'4','name':'测试'})
          // this.arrList.push({'id':'5','name':'测试'})
          // this.arrList.push({'id':'6','name':'测试'})
        })
        .catch((err) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.importPicture {
  width: 100%;
  height: 100%;
  position: relative;
}

.btnBox {
  margin-top: 30px;
  margin-bottom: 46px;
}

.btn {
  width: 34px;
  height: 34px;
  background: rgba($color: #ccdaff, $alpha: 0.2);
  border-radius: 28px;
  text-align: center;
  height: 34px;
  margin-right: 16px;
}

.bottom {
  padding-top: 20px;
}
.titileName {
  width: 100px;
}

// 切面图弹框
.btnBox1 {
  position: relative;
  .win {
    padding: 20px;
    position: absolute;
    left: -330px;
    width: 320px;
    height: 300px;
    border: 1px solid #3d6bd0;
    border-radius: 8px;
    background: #000;
    background: url("../../assets/images/img/set/tankuang_jianbian_bg.png");
    background-size: 100% 100%;
    overflow-y: auto;
    z-index: 999;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 5px;
  }
}
.winul {
  li {
    margin-bottom: 20px;
  }
}
// 上传背景图片
.upData {
  position: absolute;
  left: 50%;
  top: 30%;
  margin-left: -250px;
  width: 500px;
  height: 200px;
  // border: 1px solid #ccc;
  background: #000;
  z-index: 4;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.btnSave {
  // width: 64px;
  // height: 30px;
}

.activeColor {
  color: #fff;
}
.activeNor {
  color: #7387c6;
}

.tabActiveColor {
  background: #0050b4 !important;
}
</style>

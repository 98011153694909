<template>
  <div class="zheng box" id="">
    <div v-if="status.length==0" class="box center around" style="width:100%">
      <div class="box box_column center">
        <div class="iconfont icon-ic_image_upload" style="font-size:60px"></div>
        <div>上传图片</div>
      </div>
    </div>
    <div v-if="status.length > 0" class="zhengBox box">
          <div class="title">立面图</div>
        <!-- <div class="input box center justify">
          <div>=</div>
          <div>{{name}}</div>
          <div>=</div>
        </div> -->
        <div id="divBox" class="box center">
          <div id="line"></div>
          <div class="bgImg box" 
              v-html="src">
            </div>
          <!-- <img   :src="src" alt="" class="bgImg"> -->
        </div>
    </div>

    
  </div>
</template>

<script>

export default {
  name: 'zheng',

  // watch:{
  //   addLine(n, o){
  //     if(n == 1){
  //       console.log(`n`, n)
  //       // this.centerShow();
  //     }
  //   }
  // },

  data(){
      return{
        status:[],    //是否有图纸。
        name:'名称',
        list:[],  //侧视图
        // src: require('../../../../../几面图/立面图.svg'),
        src:'',
        x:'',  //保存截面线
        projectId:'',
      }
  },
  //创建
  created(){
    this.projectId = sessionStorage.getItem('projectId')

    //  删除了图片需要更新
    this.$bus.$on('delData', this.delData)
    this.$bus.$on('setX', this.setX)    //接收传过来的 x值
  },

  //安装
  mounted(){
    this.imgData();     //是否有三视图
  },
  
  //方法
  methods:{
    // 图片删除 更新状态
    delData(e){
      console.log('删除操作');
      if(e ==1){
        this.imgData();   //重新读取三视图
      }
    },

    setX(e){
      var dom = document.getElementById('line');
      dom.style.left = e + '%';
    },


  

    // -----------------------请求数据------------------------------------
    // 是否有三视图
    imgData(){
        this.$axios.get(`${this.baseURL}synthesis/views/list/${this.projectId}`).then(res => {
             console.log('获取立面图1', res.data.data);
            var data = res.data.data;
            console.log('1');
            data.forEach((item, index) => {
              if(item.typeId == 1){
                 this.status = item.list;
              }
            });
            console.log('2');
            if(this.status == null){
              this.status = [];
            }else{
              this.imgSrc(this.status[0].id)
            }
            console.log('3');
            // // this.status = [{}];    //设置有没有截面图
            // console.log('status', this.status.length);
            
        }).catch(err=>{
        })
    },

    imgSrc(id){
      this.$axios.get(`${this.baseURL}synthesis/img/${id}`,).then(res => {
            console.log('获取图片流');
            this.src = res.data;
        }).catch(err=>{
      })


    },



    










  }


}
</script>
<style  scoped lang="scss">
    .zheng{
        width: 100%;
        height: 100%;
        // background: url('../../../assets/images/img/zhengshi_bg.png');
        // border-radius: 28px;
        // background: linear-gradient(to top, #102048, #17356F);
        background-size: 100% 100%;
        border: 1px solid #0C3380;
        border-right: 0px;
        position: relative;
        padding-left: 60px;
        padding-right: 60px;
        .zhengBox{
          width: 100%;
          height: 100%;
        }
       

        // .title{
        //   position: absolute;
        //   bottom: 80px;
        //   left: 50%;
        //   margin-left: -30px;
        //   user-select: none;
        // }
        .input{
          position: absolute;
          bottom: 20px;
          left: 50%;
          margin-left: -60px;
          width: 120px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid #8A94A6;
          background: #04153F;
          padding: 0 20px;
        }
    }
    #divBox{
      width: 100%;
      height: 100%;
      user-select: none;
      overflow: hidden;
      position: relative;
      z-index: 2;
       .bgImg{
          width: 100%;
          // height: 100%;
          // pointer-events: none;
          // border: 1px solid #ccc;
            z-index: 2;
        }
    }


    #kuai{
      cursor: pointer;
      position: absolute;  /*定位*/
      z-index: 99;
      width: 6px;
      height: 100%;
      background: blue;  /*设置一下背景*/
    }

    #line{
      position: absolute;
      width: 6px;
      height: 100%;
      background: url('../../../assets/images/img/set/zhengshi_xuxian 2.png');
      background-size: 100% 100%;
    }

    .title{
      position: absolute;
      top: 32px;
      left: 32px;
      width: 80px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      background: rgba(0, 7, 23, 0.6);
      border-radius: 8px;
      // bottom: 30px;
  }
    
</style>